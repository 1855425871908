import ApiService from "@/services/jobsiteTrackingService";

export const state = {
    config: {
        steps: null,
        defaultStep: [],
        typeScan: 0,
        isLoaded: false,
        permissions: null,
        canCheckAll: false,
        canCompleteStep: false,
        stepSignatureRequired: null,
    }
};

export const getters = {
    steps: state => state.config.steps,
    defaultStep: state => state.config.defaultStep
};

export const mutations = {
    setDefaultStep(state, defaultStep) {
        state.config.defaultStep = defaultStep;
    },
    setSteps(state, steps) {
        state.config.steps = steps;
    },
    setTypeScan(state, typeScan) {
        state.config.typeScan = typeScan;
    },
    setIsLoaded(state, isLoaded) {
        state.config.isLoaded = isLoaded;
    },
    setCanCheckAll(state, canCheckAll) {
        state.config.canCheckAll = canCheckAll;
    },
    setCanCompleteStep(state, canCompleteStep) {
        state.config.canCompleteStep = canCompleteStep;
    },
    stepSignatureRequired(state, stepSignatureRequired) {
        state.config.stepSignatureRequired = stepSignatureRequired;
    }
};

export const actions = {
    getUserConfig({ commit }, userId) {
        return new Promise((resolve, reject) => {
            if (!state.config.isLoaded) {
                ApiService.getStepConfig(userId).then(res => {
                    commit('setIsLoaded', true);
                    commit('setTypeScan', res.data.data.typeScan);
                    commit('setCanCheckAll', res.data.data.canCheckAll);
                    commit('setCanCompleteStep', res.data.data.canCompleteStep);
                    commit('setDefaultStep', res.data.data.defaultStep);
                    commit('stepSignatureRequired', res.data.data.stepSignatureRequired);
                })
                    .catch(() => {
                        reject();
                    });
            } else {
                resolve(state.config);
            }
        });
    },
    setUserConfig({ commit }, newConfig) {
        return new Promise((resolve, reject) => {
            if (newConfig.defaultStep == null || newConfig.defaultStep == state.config) {
                resolve(state.config);
            }
            ApiService.setDefaultStep(newConfig.userId, newConfig.defaultStep).then(() => {
                commit('setDefaultStep', newConfig.defaultStep);
                resolve(state.config);
            })
                .catch(() => {
                    reject();
                });
        });
    },
    setTypeScan({ commit }, typeScan) {
        return new Promise((resolve) => {
            commit('setTypeScan', typeScan);
            resolve(state.config);
        });
    },
    clearUserConfig({ commit }) {
        commit('setIsLoaded', false);
    }
};