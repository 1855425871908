import Vue from 'vue'

export default {
    getPackings(request) {
        return Vue.prototype.$axiosMainClient.post("packings", request);
    },
    getWorkOrders(request) {
        return Vue.prototype.$axiosMainClient.post("workorders", request);
    },
    getPackingWorkOrder(workOrderIdPublic){
        return Vue.prototype.$axiosMainClient.get("workorders/bypackingworkorderidpublic/" + workOrderIdPublic);
    },
    getUsers(filter = null, page = null, pageSize = null){
        var filterString = filter == null || filter == "" || typeof filter == "undefined" ? "" : "filter=" + filter;
        filterString = page == null ? filterString : filterString + "&page=" + page;
        filterString = pageSize == null ? filterString : filterString + "&pageSize=" + pageSize;
  
        return Vue.prototype.$axiosMainClient.get("users?" + filterString) 
    },
    getUser(userid){
        return Vue.prototype.$axiosMainClient.get("users/" + userid);
    },
    getOrganisationConfig() {
        return Vue.prototype.$axiosMainClient.get("organisation");
    },
    saveOrganisationConfig(organisationConfig) {
        return Vue.prototype.$axiosMainClient.put("organisation", organisationConfig);
    },
    saveUserConfig(userConfig) {
        return Vue.prototype.$axiosMainClient.put("users", userConfig);
    },
    getInstallers() {
        return Vue.prototype.$axiosMainClient.get("installers");
    },
    getShippers() {
        return Vue.prototype.$axiosMainClient.get("shippers");
    },
    getStepConfig(userId){
        return Vue.prototype.$axiosMainClient.get("users/stepconfig/" + userId);
    },
    setDefaultStep(userId, defaultStep) {
        return Vue.prototype.$axiosMainClient.post("users/defaultstep", { userId: userId, DefaultStepIdPublic: defaultStep});
    },
    getReasons() {
        return Vue.prototype.$axiosMainClient.get("reasons");
    }, 
    completeProduct(completeProductRequest) {
        return Vue.prototype.$axiosMainClient.post("products/completeproduct", completeProductRequest);
    },
    completeWorkOrderSteps(workOrderIdPublic, stepidpublic) {
        return Vue.prototype.$axiosMainClient.post("workorders/" + workOrderIdPublic + "/workordersteps/" + stepidpublic + "/completestep");
    },
    getComments(workOrderIdPublic) {
        return Vue.prototype.$axiosMainClient.get("workorders/" + workOrderIdPublic + "/comments");
    },
    createComment(comment) {
        return Vue.prototype.$axiosMainClient.post("workorders/comments", {
            UserId: "", 
            WorkOrderStepIdPublic: comment.workOrderStepIdPublic,
            Message: comment.message,
            Type: 0
        });
    },
    getParts(workOrderIdPublic) {
        return Vue.prototype.$axiosMainClient.get("workorders/" + workOrderIdPublic + "/parts");
    },
    sendAlert(alert) {
        return Vue.prototype.$axiosMainClient.post("alerts", alert);
    }
}