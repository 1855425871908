import Vue from 'vue'
import BootstrapVue from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import Vuelidate from 'vuelidate'
import VueMask from 'v-mask'
import VueRouter from 'vue-router'
import vco from "v-click-outside"
import router from './router/index'
import Scrollspy from 'vue2-scrollspy';
import VueSweetalert2 from 'vue-sweetalert2';
import axios from 'axios';
import Dayjs from 'dayjs';
import VueAppInsights from "@/services/app-insights";

import "../src/design/index.scss";
Vue.prototype.$dayjs = Dayjs

import store from '@/state/store'

import App from './App.vue'

import i18n from './i18n'

import tinymce from 'vue-tinymce-editor';
import MSALWrapper from "./authUtils";


import DocumentationPlugin from "vue-common/src/components/documentation-component";
import AppConfig from "./app.config.json";

Vue.use(DocumentationPlugin, {
  dropboxOptions: {
    base_url: 'https://api.dropboxapi.com/2/',
    clientId: AppConfig.dropbox_client_id,
    base_path: "/Apps/web-cab/"
  },
  googleDriveOptions: {
    gapi_url: 'https://apis.google.com/js/api.js',
    gsi_url: 'https://accounts.google.com/gsi/client',
    baseUrl: 'https://www.googleapis.com',
    scopes: 'https://www.googleapis.com/auth/drive.file',
    clientId: AppConfig.google_drive_client_id,
    apiKey: AppConfig.google_drive_api_key,
    discoveryDoc: 'https://www.googleapis.com/discovery/v1/apis/drive/v3/rest',
    redirect_uri: window.location.origin,
    appFolderName: "web-cab-doc",
    requestOrigin: 'JobsiteTracking',
  },
  oneDriveOptions:{
      clientId: AppConfig.documentation_client_id,
      requestOrigin: 'JobsiteTracking',
      appFolderName: "web-cab-doc",
      graphUrl: 'https://graph.microsoft.com/v1.0/'
  },
  organisationApiUrl: AppConfig.organisation_api,
  getTokenFunction: async () => {
    return await Vue.prototype.$msal.getOrganisationApiToken();
  }
})


let redirectUrl = window.location.origin;
let msalUtils = new MSALWrapper(redirectUrl);
Vue.prototype.$msal = msalUtils;

let config = {
  //withCredentials permet de recevoir et envoyer les cookies...
  withCredentials: true,
  baseURL: AppConfig.api
};
var verifTokenExpiration = async (config) => {
  //verif expiration
  let currentAccount = Vue.prototype.$msal.currentAccount;
  if(currentAccount != null){
    const token = await Vue.prototype.$msal.getToken();
    if (token) {
      Object.assign(config, {headers: {authorization: "Bearer " + token}});
    }
    return config;
  }
}

let axiosMainClient = axios.create(config);

axiosMainClient.interceptors.request.use(
  verifTokenExpiration,
  (error) => Promise.reject(error)
);
Vue.prototype.$axiosMainClient = axiosMainClient;

Vue.component('tinymce', tinymce)
Vue.use(VueRouter)
Vue.use(vco);
Vue.use(Scrollspy);
const VueScrollTo = require('vue-scrollto')
Vue.use(VueScrollTo)
Vue.config.productionTip = false

Vue.use(BootstrapVue)
Vue.use(Vuelidate)
Vue.use(VueMask)
Vue.use(require('vue-chartist'))
Vue.use(VueSweetalert2);
Vue.component('apexchart', VueApexCharts)

Vue.use(VueAppInsights, "JobsiteTrackingUI", {
  instrumentationKey: AppConfig.app_insight,
  disableAjaxTracking: true,
  disableFetchTracking: true,
  disableExceptionTracking: true,
  samplingPercentage: 100
});

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
