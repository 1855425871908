<script>

import { notificationMethods } from "@/state/helpers";

export default {
  name: "app",
  page: {
    // All subcomponent titles will be injected into this template.
    // eslint-disable-next-line no-unused-vars
    titleTemplate(title) {
      let documentTitle = this.$route.meta.title;
      return documentTitle ? `${this.$t("titles." + documentTitle)} | ${this.$t("titles.jobsite")}` : this.$t("titles.jobsite");
    }
  },
  mounted() {
    // document.querySelector("html").setAttribute('dir', 'rtl')
  },
  watch: {
    /**
     * Clear the alert message on route change
     */
    // eslint-disable-next-line no-unused-vars
    $route(to, from) {
      // clear alert on location change
      this.clearNotification();
    },
    locale: function () {
      localStorage.setItem('jobsiteLocale', this.$i18n.locale);
      document.title = this.documentTitle;
    }
  },
  methods: {
    clearNotification: notificationMethods.clear,
  },
  computed: {
    locale: function(){
      return this.$i18n.locale;
    },
    documentTitle: function() {
      let title = this.$route.meta.title;
      return title ? `${this.$t("titles." + title)} | ${this.$t("titles.jobsite")}` : this.$t("titles.jobsite");
    }
  }
};
</script>

<template>
  <div id="app">
    <RouterView />
  </div>
</template>
