//import Vue from 'vue'
import Store from '@/state/store'

export function userHasPermissions(userPermissions, permissionsRequiredList) {
  if(permissionsRequiredList == null || permissionsRequiredList.length == 0)
    return true;

  let hasPermissions = true;

  for(var permissionRequired of permissionsRequiredList){
    hasPermissions = userPermissions[permissionRequired];
    if(hasPermissions == false){
      break;
    }

  }

  return hasPermissions;
}

var UserConfigMixin = {
  data () {
    return {

    }
  },
  methods: {
    userCanAccessRoute(menuItem){
      if(menuItem.meta?.rolesRequired == null)
        return true;

      let hasRoles = true;

      let userRole = this.userRoles;
      let rolesRequiredList = menuItem.meta.rolesRequired.split(",");
      //let rolesRequiredList = ["admin"];
      for(let roleRequired of rolesRequiredList){
        let userHasRole = userRole == roleRequired;
        if(!userHasRole){
          hasRoles = false
          break;
        }
      }

      return hasRoles;
    },
    userHasPermissions,
    userHasPermissionsForMenuItem(menuItem){
      let userPermissions = this.userConfig.permissions;
      let permissionsRequiredList = menuItem.meta?.permissionsRequired?.split(",");  
      
      return userHasPermissions(userPermissions, permissionsRequiredList)
    },
    stepNeedSignature(stepId) {
      return this.userConfig.stepSignatureRequired === null ? null : this.userConfig.stepSignatureRequired.includes(stepId)
    }
  },
  computed: {
    userIsAdmin(){
      return this.$msal.currentAccount.idTokenClaims.extension_DeliveryScanRoles.toLowerCase() == "admin";
    },
    userConfig() {
      return Store.state.userconfig.config
    },
    userRoles() {
      return this.$msal.currentAccount.idTokenClaims.extension_DeliveryScanRoles;
    }            
  }
}

export default UserConfigMixin