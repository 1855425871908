import Vue from 'vue';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';


function install(Vue, applicationName, config) {
    Vue.appInsights = new ApplicationInsights({
        config: config
    });
    Vue.appInsightsService = new AppInsightsService(applicationName);
    Vue.appInsights.loadAppInsights();
    Object.defineProperty(Vue.prototype, 'trackEvent', {
      get: () => (name) => Vue.appInsightsService.trackEvent(name)
    });
    Object.defineProperty(Vue.prototype, 'startTrackPage', {
      get: () => (route) => Vue.appInsightsService.startTrackPage(route)
    });
    Object.defineProperty(Vue.prototype, 'stopTrackPage', {
      get: () => (route) => Vue.appInsightsService.stopTrackPage(route)
    });
    Object.defineProperty(Vue.prototype, 'setAuthenticatedUserContext', {
      get: () => (username, organisationcode) => Vue.appInsightsService.setAuthenticatedUserContext(username, organisationcode)
    });
}
export class AppInsightsService {
    constructor(applicationName) {
        this.applicationName = applicationName;
    }
    trackEvent(name) {
        Vue.appInsights.trackEvent({ name: this.applicationName + "-" + name });
        Vue.appInsights.flush();
    }

    startTrackPage(route) {
        if (route.meta.track === true) {
            Vue.appInsights.startTrackPage(this.applicationName + ' / ' + route.name);
        }
    }

    stopTrackPage(route) {
        if (route.meta.track === true) {
            const url = location.protocol + '//' + location.host + route.fullPath;
            Vue.appInsights.stopTrackPage(this.applicationName + ' / ' + route.name, url);
        }
        Vue.appInsights.flush();
    }

    setAuthenticatedUserContext(username, organisationcode) {
        Vue.appInsights.setAuthenticatedUserContext(username, organisationcode, true);
    }
}
// auto install for navigator
if (typeof window !== 'undefined' && window.Vue) {
    window.Vue.use(install)
}

export default install

